import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createDeployReleaseFailure,
  createDeployReleaseRequest,
  createDeployReleaseSuccess,
  deleteReleaseFailure,
  deleteReleaseRequest,
  deleteReleaseSuccess,
  getReleaseApiEndPointsFailure,
  getReleaseApiEndPointsRequest,
  getReleaseApiEndPointsSuccess,
  getReleaseFailure,
  getReleaseRequest,
  getReleasesFailure,
  getReleasesRequest,
  getReleasesSuccess,
  getReleaseSuccess,
  getTestReleaseFailure,
  getTestReleaseRequest,
  getTestReleaseSuccess,
  getWorkflowReleasesFailure,
  getWorkflowReleasesRequest,
  getWorkflowReleasesSuccess,
  updateReleaseNotesFailure,
  updateReleaseNotesRequest,
  updateReleaseNotesSuccess,
  downloadBlueprintRequest,
  downloadBlueprintSuccess,
  downloadBlueprintFailure,
  createValidationSetRequest,
  createValidationSetSuccess,
  createValidationSetFailure,
  notifyValidationSetUploadRequest,
  notifyValidationSetUploadSuccess,
  notifyValidationSetUploadFailure,
} from "redux/releases/action";
import { data } from "react-dom-factories";

function* getReleases({ payload }) {
  try {
    let url = "/releases";
    if (payload) {
      url = `releases?page=${payload}`;
    }
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getReleasesSuccess(response.data));
    }
  } catch (e) {
    yield put(getReleasesFailure("e.message"));
  }
}

function* getRelease({ payload }) {
  try {
    const url = `releases/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getReleaseSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getReleaseFailure("e.message"));
  }
}

function* getWorkflowReleases({ payload }) {
  try {
    const url = `workflows/${payload}/releases`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getWorkflowReleasesSuccess(response.data));
    }
  } catch (e) {
    yield put(getWorkflowReleasesFailure("e.message"));
  }
}

function* deleteRelease({ payload }) {
  try {
    const url = `releases/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteReleaseSuccess());
    }
  } catch (e) {
    yield put(deleteReleaseFailure("e.message"));
  }
}

function* getTestRelease({ payload }) {
  try {
    const url = `releases/${payload.id}/execute?call_ext_data=${payload.callExtData}&record_steps=${payload.recordSteps}`;
    const response = yield call(axios.post, url, payload.testingInputData);
    if (response.status === 200) {
      yield put(getTestReleaseSuccess(response.data));
    }
  } catch (e) {
    yield put(getTestReleaseFailure("e.message"));
  }
}

function* getReleaseApiEndPoints({ payload }) {
  try {
    const url = `api-endpoints?workflow_release_id=${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getReleaseApiEndPointsSuccess(response.data));
    }
  } catch (e) {
    yield put(getReleaseApiEndPointsFailure("e.message"));
  }
}

function* createDeployRelease({ payload }) {
  try {
    const response = yield call(axios.post, "deployments", payload);
    if (response.status === 201) {
      yield put(createDeployReleaseSuccess());
    }
  } catch (e) {
    yield put(createDeployReleaseFailure(e.response.status));
  }
}

function* updateReleaseNotes({ payload }) {
  try {
    const url = `releases/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateReleaseNotesSuccess());
    }
  } catch (e) {
    yield put(updateReleaseNotesFailure("e.message"));
  }
}

function* downloadBlueprint({ payload }) {
  try {
    const response = yield call(
      axios.get,
      `releases/${payload.id}/blueprints`,
      {
        responseType: "blob",
      }
    );
    if (response.status === 200) {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", payload.filename || "blueprint.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      yield put(downloadBlueprintSuccess());
    } else {
      yield put(downloadBlueprintFailure("Failed to download blueprint"));
    }
  } catch (e) {
    yield put(downloadBlueprintFailure(e.message));
  }
}

function* createValidationSet({ payload }) {
  try {
    const response = yield call(axios.post, "/validation-sets", payload);
    if (response.status === 201) {
      yield put(
        createValidationSetSuccess({
          data: response.data,
          eTag: response.headers.etag,
        })
      );
    }
  } catch (e) {
    yield put(createValidationSetFailure(e.message));
  }
}

function* notifyValidationSetUpload({ payload }) {
  try {
    const response = yield call(
      axios.post,
      "/validation-sets/" +
        payload.release_validation_set_id +
        "/notifications",
      payload
    );
    if (response.status === 201) {
      yield put(notifyValidationSetUploadSuccess());
    } else {
      yield put(
        notifyValidationSetUploadFailure(
          "Failed to notify validation set upload"
        )
      );
    }
  } catch (e) {
    yield put(notifyValidationSetUploadFailure(e.message));
  }
}

export default function* saga() {
  yield takeLatest(getReleasesRequest, getReleases);
  yield takeLatest(getReleaseRequest, getRelease);
  yield takeLatest(getWorkflowReleasesRequest, getWorkflowReleases);
  yield takeLatest(deleteReleaseRequest, deleteRelease);
  yield takeLatest(getTestReleaseRequest, getTestRelease);
  yield takeLatest(getReleaseApiEndPointsRequest, getReleaseApiEndPoints);
  yield takeLatest(createDeployReleaseRequest, createDeployRelease);
  yield takeLatest(updateReleaseNotesRequest, updateReleaseNotes);
  yield takeLatest(downloadBlueprintRequest, downloadBlueprint);
  yield takeLatest(createValidationSetRequest, createValidationSet);
  yield takeLatest(notifyValidationSetUploadRequest, notifyValidationSetUpload);
}
