import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AutoSuggestInput from "components/autosuggest";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";

const WeightInput = ({
  vector,
  weight,
  weight_attribute_path,
  onWeightChange,
  onPathChange,
  onVectorChange,
  disabled,
  vectorsData,
}) => {
  const { t } = useTranslation();

  // Refs
  const inputGroupRef = useRef(null);

  // State management
  const [inputValue, setInputValue] = useState(weight.toString());
  const [isNumberMode, setIsNumberMode] = useState(
    vector == "0" || vector === undefined
  );
  const [showModal, setShowModal] = useState(false);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);

  // Synchronize inputValue with weight when in number mode
  useEffect(() => {
    if (isNumberMode) {
      setInputValue(weight.toString());
    }
  }, [weight, isNumberMode]);

  // Synchronize inputValue with attribute_path when not in number mode
  useEffect(() => {
    if (!isNumberMode) {
      setInputValue(weight_attribute_path || "");
    }
  }, [weight_attribute_path, isNumberMode]);

  // Toggle between numeric input and attribute path
  const toggleMode = () => {
    const newMode = !isNumberMode;
    setIsNumberMode(newMode);
    if (newMode) {
      // Switching to Value Mode
      onWeightChange(weight);
      onVectorChange(0); // Set vector to 0
    } else {
      // Switching to Attribute Path Mode
      onPathChange(weight_attribute_path);
      onVectorChange(1); // Set vector to 1
    }
  };

  // Handle changes in the numeric input field (during typing)
  const handleChange = (e) => {
    if (disabled) return;
    const value = e.target.value;
    const reg = /^-?\d*(\.\d*)?$/;
    if (!value || reg.test(value)) {
      setInputValue(value);
    }
  };

  // Handle blur event (when user finishes inputting the number)
  const handleBlur = (e) => {
    const value = parseFloat(e.target.value);
    // Check if the value is a valid number
    if (!isNaN(value)) {
      setInputValue(value.toString()); // Ensure consistency in state
      onWeightChange(value); // Update parent with new valid value
    } else {
      // Reset to the previous valid value if input is invalid
      setInputValue(weight.toString());
    }
  };

  // Open modal for attribute path input
  const openModal = () => {
    if (!isNumberMode) {
      setShowModal(true);
    }
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Handle change from AutoSuggestInput
  const handleAutoSuggestChange = (value) => {
    onPathChange(value); // Propagate path change
  };

  // Right-click handler to show context menu
  const handleRightClick = (e) => {
    if (disabled) return;
    e.preventDefault();
    setContextMenuVisible(true);
  };

  // Close context menu
  const closeContextMenu = () => {
    setContextMenuVisible(false);
  };

  // Close context menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputGroupRef.current &&
        !inputGroupRef.current.contains(event.target)
      ) {
        closeContextMenu();
      }
    };
    if (contextMenuVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenuVisible]);

  return (
    <>
      <div
        className="input-group justify-content-between"
        onContextMenu={handleRightClick}
        style={{ position: "relative" }}
        ref={inputGroupRef}
      >
        {/* Input field */}
        {isNumberMode ? (
          <input
            type="text"
            value={inputValue}
            className="form-control border-0 rounded-0"
            onChange={handleChange}
            onBlur={handleBlur} // Handle input validation on blur
            disabled={disabled}
          />
        ) : (
          <div className="btn input-group-text" onClick={openModal}>
            {"{" + (weight_attribute_path ? "$" : "...") + "}"}
            <EditIcon className="pl-2" />
          </div>
        )}

        {/* Right-click context menu */}
        {contextMenuVisible && (
          <div
            className="context-menu"
            style={{
              position: "absolute",
              top: "100%", // Position below the input group
              left: 0,
              zIndex: 1000,
            }}
          >
            <ul className="dropdown-menu show">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    toggleMode();
                    closeContextMenu();
                  }}
                >
                  {isNumberMode ? t("attribute") : t("value")}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>

      {/* Modal for AutoSuggestInput */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header>
          <h5 className="modal-title">{t("attribute_path")}</h5>
          <button type="button" className="close" onClick={closeModal}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="col mr-0 ml-0 subflow-option">
            <div className={`input-group`}>
              <div className="bg-white border border-radius-left-4 input-group-prepend">
                <div
                  className="align-items-center d-flex font-size-14 pl-2 text-gray-66"
                  htmlFor={"attribute_path"}
                >
                  {"{...}"}
                </div>
              </div>
              <AutoSuggestInput
                vectorsData={vectorsData}
                defaultValue={weight_attribute_path || ""}
                inputName="weight_attribute_path"
                onInputChange={handleAutoSuggestChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="" className="outline my-0" onClick={closeModal}>
            {t("cancel")}
          </Button>
          <Button variant="" className="primary my-0" onClick={closeModal}>
            {t("save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

WeightInput.propTypes = {
  weight: PropTypes.number.isRequired,
  weight_attribute_path: PropTypes.string,
  onWeightChange: PropTypes.func.isRequired,
  onPathChange: PropTypes.func.isRequired,
  onVectorChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  vector: PropTypes.any,
  vectorsData: PropTypes.any,
};

export default WeightInput;
