import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dataMarketGetServicesRequest,
  dataMarketActivateServiceRequest,
  dataMarketDeactivateMyServiceRequest,
  dataMarketGetServiceDetailsRequest,
  dataMarketUpdateServiceDetailsRequest, // Import the update action
} from "redux/dataMarket/action";
import ServiceCard from "./ServiceCard";
import SubHeader from "components/SubHeader";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { isArray } from "lodash";
import "assets/css/dataMarketplace.scss";
import usePrevious from "utility/hooks/usePrevious";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteConfirm from "components/modals/DeleteConfirm";

const DataMarketServicesPage = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    services,
    isLoading,
    dataServiceDetails,
    isDataMarketActivateServiceSuccess,
    isDataMarketActivateServiceError,
    isDataMarketDeactivateMyServiceSuccess,
    isDataMarketGetServiceDetailsSuccess,
    isDataMarketGetServicesSuccess,
    isDataMarketGetServicesError,
    isDataMarketUpdateServiceDetailsSuccess, // Add update success flag
    isDataMarketUpdateServiceDetailsError, // Add update error flag
  } = useSelector((state) => state.dataMarket);

  // Cloned services state
  const [clonedServices, setClonedServices] = useState([]);

  const [activeService, setActiveService] = useState(null);
  const [serviceDetails, setServiceDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // State for API credentials and T&C agreement
  const [apiKey, setApiKey] = useState("");
  const [apiUsername, setApiUsername] = useState("");
  const [apiPassword, setApiPassword] = useState("");
  const [hasAgreedToTnC, setHasAgreedToTnC] = useState(false);

  // New state for showing update fields
  const [showUpdateFields, setShowUpdateFields] = useState(false);

  const prevIsDataMarketActivateServiceSuccess = usePrevious(
    isDataMarketActivateServiceSuccess
  );
  const prevIsDataMarketActivateServiceError = usePrevious(
    isDataMarketActivateServiceError
  );
  const prevIsDataMarketDeactivateMyServiceSuccess = usePrevious(
    isDataMarketDeactivateMyServiceSuccess
  );
  const prevIsDataMarketGetServiceDetailsSuccess = usePrevious(
    isDataMarketGetServiceDetailsSuccess
  );

  const prevIsDataMarketUpdateServiceDetailsSuccess = usePrevious(
    isDataMarketUpdateServiceDetailsSuccess
  );
  const prevIsDataMarketUpdateServiceDetailsError = usePrevious(
    isDataMarketUpdateServiceDetailsError
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [serviceToDeactivate, setServiceToDeactivate] = useState(null);

  // Fetch services on component mount
  useEffect(() => {
    // Set the page title and initiate the services fetch
    document.title = `${t("data_services_hub")} - Decisimo`;
    setIsLoading(true);
    dispatch(dataMarketGetServicesRequest());
  }, [dispatch, setIsLoading, t]);

  // Clone services when fetched successfully
  useEffect(() => {
    if (isDataMarketGetServicesSuccess && isArray(services)) {
      // Clone the services array
      setClonedServices([...services]);
      setIsLoading(false);
    }
    if (isDataMarketGetServicesError) {
      setIsLoading(false);
    }
  }, [isDataMarketGetServicesSuccess, isDataMarketGetServicesError, services]);

  // Handle activation and deactivation success/error
  useEffect(() => {
    if (
      isDataMarketActivateServiceSuccess &&
      !prevIsDataMarketActivateServiceSuccess
    ) {
      toast.success(t("service_activated_successfully"), ToastOptions);
      // Update clonedServices to reflect activation
      setClonedServices((prevClonedServices) =>
        prevClonedServices.map((service) =>
          service.dama_service_id === activeService.dama_service_id
            ? { ...service, tenant_service_status: "a" }
            : service
        )
      );
      setIsModalOpen(false); // Close activation modal on success
      setIsLoading(false);
    }
    if (
      isDataMarketActivateServiceError &&
      !prevIsDataMarketActivateServiceError
    ) {
      toast.error(t("service_activation_failed"), ToastOptions);
      setIsLoading(false);
    }
    if (
      isDataMarketDeactivateMyServiceSuccess &&
      !prevIsDataMarketDeactivateMyServiceSuccess
    ) {
      toast.success(t("service_deactivated_successfully"), ToastOptions);
      // Update clonedServices to reflect deactivation
      setClonedServices((prevClonedServices) =>
        prevClonedServices.map((service) =>
          service.dama_service_id === serviceToDeactivate
            ? { ...service, tenant_service_status: "i" }
            : service
        )
      );
      setShowEditModal(false); // Close edit modal on success
      setIsLoading(false);
    }
  }, [
    isDataMarketActivateServiceSuccess,
    isDataMarketActivateServiceError,
    isDataMarketDeactivateMyServiceSuccess,
    prevIsDataMarketActivateServiceSuccess,
    prevIsDataMarketActivateServiceError,
    prevIsDataMarketDeactivateMyServiceSuccess,
    activeService,
    serviceToDeactivate,
    t,
  ]);

  // Handle update success/error
  useEffect(() => {
    if (
      isDataMarketUpdateServiceDetailsSuccess &&
      !prevIsDataMarketUpdateServiceDetailsSuccess
    ) {
      toast.success(t("service_updated_successfully"), ToastOptions);
      if (activeService) {
        // Update clonedServices to reflect the changes
        setClonedServices((prevClonedServices) =>
          prevClonedServices.map((service) =>
            service.dama_service_id === activeService.dama_service_id
              ? {
                  ...service,
                  custom_api_key: apiKey,
                  custom_api_user: apiUsername,
                  custom_api_password: apiPassword,
                }
              : service
          )
        );
      }
      setShowEditModal(false); // Close edit modal on success
      setIsLoading(false);
    }

    if (
      isDataMarketUpdateServiceDetailsError &&
      !prevIsDataMarketUpdateServiceDetailsError
    ) {
      toast.error(t("service_update_failed"), ToastOptions);
      setIsLoading(false);
    }
  }, [
    isDataMarketUpdateServiceDetailsSuccess,
    isDataMarketUpdateServiceDetailsError,
    prevIsDataMarketUpdateServiceDetailsSuccess,
    prevIsDataMarketUpdateServiceDetailsError,
    apiKey,
    apiUsername,
    apiPassword,
    activeService,
    t,
  ]);

  // Handle service details fetch success
  useEffect(() => {
    if (
      isDataMarketGetServiceDetailsSuccess &&
      !prevIsDataMarketGetServiceDetailsSuccess
    ) {
      setServiceDetails(dataServiceDetails);
      // Pre-fill the form fields with existing data
      setApiKey(dataServiceDetails.custom_api_key || "");
      setApiUsername(dataServiceDetails.custom_api_user || "");
      setApiPassword(dataServiceDetails.custom_api_password || "");
      setShowEditModal(true); // Open edit modal after fetching details
      setIsLoading(false);
    }
  }, [
    isDataMarketGetServiceDetailsSuccess,
    prevIsDataMarketGetServiceDetailsSuccess,
    dataServiceDetails,
  ]);

  const handleActivate = () => {
    if (activeService) {
      setIsLoading(true);
      dispatch(
        dataMarketActivateServiceRequest({
          dama_service_id: activeService.dama_service_id,
          custom_api_key: apiKey,
          custom_api_user: apiUsername,
          custom_api_password: apiPassword,
        })
      );
    }
  };

  const handleGetServiceDetails = (serviceId) => {
    setIsLoading(true);
    dispatch(
      dataMarketGetServiceDetailsRequest({ dama_service_id: serviceId })
    );
    setActiveService(
      clonedServices.find((service) => service.dama_service_id === serviceId)
    ); // Store active service from clonedServices
  };

  const handleDeactivate = (serviceId) => {
    setServiceToDeactivate(serviceId);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDeactivate = () => {
    if (serviceToDeactivate) {
      setIsLoading(true);
      dispatch(
        dataMarketDeactivateMyServiceRequest({
          dama_service_id: serviceToDeactivate,
        })
      );
      setIsDeleteModalOpen(false);
    }
  };

  const handleUpdate = () => {
    if (activeService) {
      setIsLoading(true);
      dispatch(
        dataMarketUpdateServiceDetailsRequest({
          dama_service_id: activeService.dama_service_id,
          custom_api_key: apiKey,
          custom_api_user: apiUsername,
          custom_api_password: apiPassword,
        })
      );
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setShowEditModal(false);
    setShowUpdateFields(false); // Reset the update fields visibility
    setApiKey("");
    setApiUsername("");
    setApiPassword("");
    setHasAgreedToTnC(false);
  };

  return (
    <>
      <SubHeader title={t("data_services_hub")} actions="" />
      <div className="data-market-services-page mt-4">
        {isLoading ? (
          <div>{t("loadingServices")}...</div>
        ) : clonedServices.length > 0 && isArray(clonedServices) ? (
          <div className="row">
            {clonedServices
              .filter((service) => service !== null)
              .map((service) => (
                <div key={service.dama_service_id} className="col-md-4 mb-4">
                  <ServiceCard
                    service={service}
                    onActivate={() => {
                      setActiveService(service); // Store the service to be activated
                      setIsModalOpen(true); // Open activation modal
                      setApiKey(""); // Clear previous values
                      setApiUsername("");
                      setApiPassword("");
                      setHasAgreedToTnC(false);
                    }}
                    onDeactivate={() =>
                      handleDeactivate(service.dama_service_id)
                    }
                    handleGetServiceDetails={() =>
                      handleGetServiceDetails(service.dama_service_id)
                    }
                  />
                </div>
              ))}
          </div>
        ) : (
          <div>{t("no_services_available")}</div>
        )}
      </div>

      {/* Activation Modal */}
      <Modal size="md" show={isModalOpen} onHide={handleModalClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("activate_service")}</h5>
          <button type="button" className="close" onClick={handleModalClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {activeService?.service_type === "byok" &&
              activeService.secret_type === "password" && (
                <>
                  <Form.Group controlId="apiUsername">
                    <Form.Label>{t("api_username")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Enter API username")}
                      value={apiUsername}
                      onChange={(e) => setApiUsername(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="apiPassword">
                    <Form.Label>{t("api_password")}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("enter_api_password")}
                      value={apiPassword}
                      onChange={(e) => setApiPassword(e.target.value)}
                    />
                  </Form.Group>
                </>
              )}
            {activeService?.service_type === "byok" &&
              activeService.secret_type === "key" && (
                <Form.Group controlId="apiKey">
                  <Form.Label>{t("api_key")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("enter_api_key")}
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                </Form.Group>
              )}
            {activeService?.service_type === "free" && (
              <p>
                {t("free_service_activation_message", {
                  provider: activeService.provider_title,
                })}
              </p>
            )}
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label={t("i_agree_to_terms_and_conditions")}
                checked={hasAgreedToTnC}
                onChange={(e) => setHasAgreedToTnC(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" onClick={handleModalClose}>
            {t("Close")}
          </Button>
          <Button
            variant=""
            className="primary my-0"
            onClick={handleActivate}
            disabled={!hasAgreedToTnC || isLoading}
          >
            <span>{isLoading ? t("activating") : t("Submit")}</span>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal */}
      <Modal size="lg" show={showEditModal} onHide={handleModalClose}>
        <Modal.Header>
          <h5 className="modal-title">{t("edit_service")}</h5>
          <button type="button" className="close" onClick={handleModalClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p>{t("service_details")}</p>
            <p>
              {t("latest_version")}: {serviceDetails?.version_id}
            </p>
            {activeService?.service_type === "byok" &&
              activeService.secret_type === "password" &&
              showUpdateFields && (
                <>
                  <Form.Group controlId="apiUsername">
                    <Form.Label>{t("api_username")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Enter API username")}
                      value={apiUsername}
                      onChange={(e) => setApiUsername(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="apiPassword">
                    <Form.Label>{t("api_password")}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("enter_api_password")}
                      value={apiPassword}
                      onChange={(e) => setApiPassword(e.target.value)}
                    />
                  </Form.Group>
                </>
              )}
            {activeService?.service_type === "byok" &&
              activeService.secret_type === "key" &&
              showUpdateFields && (
                <Form.Group controlId="apiKey">
                  <Form.Label>{t("api_key")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("enter_api_key")}
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    disabled={!showUpdateFields} // Disable when fields are hidden
                  />
                </Form.Group>
              )}
            {activeService?.service_type === "byok" && (
              <>
                {!showUpdateFields && (
                  <div className="mb-3 d-flex">
                    <Button
                      variant=""
                      onClick={() => setShowUpdateFields(true)}
                      className="mt-3 btn outline"
                    >
                      {t("update_api_secret")}
                    </Button>
                  </div>
                )}
                {showUpdateFields && (
                  <>
                    <div className="mb-3 mt-3 d-flex justify-content-end">
                      <Button
                        variant="outline"
                        className="btn mr-2 outline"
                        onClick={() => setShowUpdateFields(false)}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        variant="outline"
                        className="primary my-0"
                        onClick={handleUpdate}
                        disabled={isLoading}
                      >
                        {isLoading ? t("updating") : t("update")}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
            {serviceDetails?.external_data && (
              <>
                <strong className="mt-4">
                  {t("external_data_using_service")}
                </strong>
                <ul>
                  {serviceDetails.external_data.map((data) => (
                    <li key={data.external_data_id}>
                      <Link
                        to={`/external-data-source/${data.external_data_id}`}
                      >
                        {data.title}
                      </Link>
                      <span title={t("version")}>
                        &nbsp; v{data.dama_service_version_id}
                      </span>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button
            variant="outline"
            className="btn outline"
            onClick={() => handleDeactivate(activeService.dama_service_id)}
          >
            {t("deactivate_service")}
          </Button>
          <div>
            <Button
              variant="outline"
              className="btn outline"
              onClick={handleModalClose}
            >
              {t("close")}
            </Button>
            {/* The "Update" button is now inside the conditional rendering above */}
          </div>
        </Modal.Footer>
      </Modal>

      <DeleteConfirm
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={handleConfirmDeactivate}
        title={t("deactivate_service")}
        message={
          isArray(dataServiceDetails?.external_data) &&
          dataServiceDetails?.external_data.length > 0
            ? t("deactivate_service_message_with_external_data", {
                title: dataServiceDetails?.title || "",
              })
            : t("are_you_sure_deactivate_service", {
                title: dataServiceDetails?.title || "",
              })
        }
        confirm_button={t("deactivate")}
      />
    </>
  );
};

export default DataMarketServicesPage;
