import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { JsonStructure } from "components/JsonStructure";
import PropTypes from "prop-types";
import {
  getFirstObject,
  Loader,
  sampleSizes,
  syntheticOptions,
} from "utility/utility";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import { useDispatch, useSelector } from "react-redux";
import { MainContext } from "context/contexts";
import { useTranslation } from "react-i18next";
import ReleaseTreeTable from "../tree-table/ReleaseTreeTable";
import { set, toInteger } from "lodash";
import { createExternalDataResponseRequest } from "redux/dataSources/action";
import usePrevious from "utility/hooks/usePrevious";
import { use } from "react-dom-factories";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";

const TestingModal = (props) => {
  const {
    open,
    handleClose,
    testingData = [],
    setDecisionTableCols,
    vectorsData = [],
    title,
    size,
    isModel = false,
    handleRunTestResultDetailsConfirm,
    handleRunTestResultSuccessConfirm,
    handleRunSimulationTestConfirm,
    testingSimulationValues,
    runTestResultDetailsData,
    runTestResultStatusData,
    runTestSimulationResultData,
    tableMode = false,
    isDataSource = false,
    isFunction = false,
    isRuleSet = false,
    isScorecard = false,
    isDecisionTree = false,
    loading = false,
    rulesFired = [],
    rulesFiredDetails = [],
  } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { functionData } = useSelector((state) => state.functions);
  const { scorecard } = useSelector((state) => state.scorecard);
  const { isCreatedResponseSuccess, isCreatedResponseError } = useSelector(
    (state) => state.dataSources
  );
  const { isLoading } = useContext(MainContext);

  const [key, setKey] = useState("unit-test");
  const [vectorId, setVectorId] = useState(null);
  const [isOutputJsonView, setIsOutputJsonView] = useState(false);
  const [isOutputJsonViewDataSource, setIsOutputJsonViewDataSource] =
    useState(false);
  const [isInputJsonView, setIsInputJsonView] = useState(false);

  // New state for response storing
  const [showStoreResponse, setShowStoreResponse] = useState(false);
  const [responseName, setResponseName] = useState("");

  //
  const prevIsCreatedResponseSuccess = usePrevious(isCreatedResponseSuccess);
  const prevIsCreatedResponseError = usePrevious(isCreatedResponseError);

  const changeType = (id, type) => {
    const testingDataClone = structuredClone(testingData);
    const data = testingDataClone.find(
      (test) => test.decision_table_column_id === id
    );
    data.type = type;
    setDecisionTableCols(testingDataClone);
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: 200,
      borderRadius: 0,
    }),
    menu: (base) => ({ ...base, display: "none" }),
  };

  const checkPageType = () => {
    return (
      (isRuleSet && runTestResultStatusData?.length > 0) ||
      (isRuleSet && Object.keys(runTestResultDetailsData).length) ||
      !isRuleSet
    );
  };

  //on vector change
  useEffect(() => {
    if (vectorId == null && vectorsData?.length > 0) {
      setVectorId(vectorsData[0]?.vector_id);
    }
  }, [vectorsData]);

  const handleStoreResponse = () => {
    setShowStoreResponse(true);
  };

  const handleStoreSubmit = () => {
    const externalDataId = window.location.href.split("/").pop();
    dispatch(
      createExternalDataResponseRequest({
        external_data_id: externalDataId,
        response_name: responseName,
        json_response: JSON.stringify(runTestResultDetailsData),
      })
    );

    setShowStoreResponse(false);
    setResponseName("");
  };

  useEffect(() => {
    if (isCreatedResponseSuccess && prevIsCreatedResponseSuccess === false) {
      toast.success(t("data_response_stored_successfully"), ToastOptions);
    }
  }, [isCreatedResponseSuccess]);

  useEffect(() => {
    if (isCreatedResponseError && prevIsCreatedResponseError === false) {
      toast.error(t("failed_to_store_data_response"), ToastOptions);
    }
  }, [isCreatedResponseError]);

  const handleStoreCancel = () => {
    // Reset the response name and hide the store response form
    setResponseName("");
    setShowStoreResponse(false);
  };

  return (
    <>
      <Modal size={size} show={open} onHide={handleClose}>
        <Modal.Header>
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="close" onClick={handleClose}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {!isDataSource ? (
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="unit-test" title={t("unit_test")}>
                <form onSubmit={handleRunTestResultDetailsConfirm}>
                  <div
                    role="tabpanel"
                    className="tab-pane active mb-4"
                    aria-labelledby="unit-test-tab"
                  >
                    <div className="variables word-break">
                      {testingData.length > 0 &&
                        testingData.map((test, index) => {
                          // Generate a unique identifier for the input field
                          let unique_id =
                            test.decision_table_column_id ||
                            test.fce_attribute_id ||
                            test.scorecard_predictor_id ||
                            Math.round(Math.random() * 10000);

                          return (
                            <div key={unique_id}>
                              {isScorecard && (
                                <input
                                  type="hidden"
                                  className="form-control"
                                  name={`variable[${test.scorecard_predictor_id}][path]`}
                                  defaultValue={test.attribute_path}
                                />
                              )}
                              {isDecisionTree && (
                                <input
                                  type="hidden"
                                  className="form-control"
                                  name={`variable[${unique_id}][path]`}
                                  defaultValue={test.attribute_path}
                                />
                              )}
                              {isRuleSet && (
                                <input
                                  type="hidden"
                                  className="form-control"
                                  name={`variable[${unique_id}][path]`}
                                  defaultValue={test}
                                />
                              )}
                              {!tableMode ||
                              test.role === "condition" ||
                              (!test.role && test.attribute_path) ? (
                                <div className="row mt-2">
                                  <div
                                    className={`${
                                      tableMode ? "col-2" : "col-4"
                                    }`}
                                  >
                                    <label>
                                      {tableMode
                                        ? test.title
                                        : test.name
                                        ? test.name
                                        : typeof test === "object"
                                        ? ""
                                        : test}
                                    </label>
                                  </div>
                                  <div className="col">
                                    <input
                                      type="text"
                                      className="form-control test-data"
                                      name={`variable[${unique_id}][value]`}
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                    </div>
                    <div className="row mt-3">
                      <div className="col text-center">
                        <button
                          className={`btn outline ${
                            tableMode ? "text-success" : ""
                          }`}
                          disabled={isLoading}
                          type="submit"
                        >
                          <WindIcon /> {t("run_test")}
                        </button>
                      </div>
                    </div>
                    {loading && isModel && <Loader />}
                    {runTestResultDetailsData && !loading ? (
                      isModel ? (
                        <>
                          <div className="row my-3">
                            <div className="col-4">{t("result_status")}</div>
                            <div
                              className={`col ${
                                runTestResultDetailsData.output?.test
                                  ?.__status === "ok"
                                  ? "alert-success"
                                  : "alert-warning"
                              }`}
                            >
                              {runTestResultDetailsData.output?.test?.__status}
                            </div>
                          </div>
                          {runTestResultDetailsData.output?.test?.__status ===
                            "ok" && (
                            <div className="row mb-3">
                              <div className="col-4">{t("result_data")}</div>
                              <div className="col test-result-data">
                                {JSON.stringify(
                                  runTestResultDetailsData?.output?.test?.result
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="mt-3">
                          {isRuleSet &&
                            runTestResultDetailsData?.output?.test?.rules_fired
                              ?.length > 0 && (
                              <>
                                <div className="mb-1">
                                  {t("rules_activated")}
                                </div>
                                <div className="align-items-center d-flex flex-wrap mb-4">
                                  {rulesFiredDetails?.map((item, index) => {
                                    return (
                                      <span
                                        key={item + index}
                                        className="badge badge-light badge-pill border font-size-14 mr-2 mt-2 px-2 py-1"
                                      >
                                        {item}
                                      </span>
                                    );
                                  })}
                                </div>
                              </>
                            )}
                          <div className="mb-3">
                            {tableMode
                              ? t("result_status")
                              : t("result_details")}{" "}
                          </div>
                          {isScorecard && (
                            <div className="input-group-append pb-1">
                              <span className="input-group-text">
                                {t("score")} ={" "}
                                {Object.keys(scorecard)?.length > 0 &&
                                scorecard?.attribute_path.startsWith("$.")
                                  ? getFirstObject(
                                      runTestResultDetailsData,
                                      scorecard?.attribute_path
                                    )
                                  : ""}
                              </span>
                            </div>
                          )}
                          {isFunction ? (
                            <div className="input-group-append pb-1">
                              <span className="input-group-text">
                                f(x) ={" "}
                                {Object.keys(functionData)?.length > 0 &&
                                functionData?.info?.x_attribute_path.startsWith(
                                  "$."
                                )
                                  ? getFirstObject(
                                      runTestResultDetailsData,
                                      functionData?.info?.x_attribute_path
                                    )
                                  : ""}
                              </span>
                            </div>
                          ) : (
                            <div
                              className="col-12 mb-md-0 mb-4"
                              style={{ height: "628px" }}
                            >
                              {checkPageType() &&
                              (Object.keys(runTestResultDetailsData).length ||
                                (runTestResultStatusData &&
                                  Object.keys(runTestResultStatusData)
                                    .length)) ? (
                                isOutputJsonView ? (
                                  <JsonStructure
                                    jsonData={runTestResultDetailsData}
                                  />
                                ) : (
                                  <ReleaseTreeTable
                                    testingData={{
                                      current: runTestResultDetailsData,
                                    }}
                                    type="output"
                                  />
                                )
                              ) : null}
                              <div
                                className="convert-json btn-group-toggle"
                                data-toggle="buttons"
                              >
                                <label className="btn outline border-radius-left-4 active">
                                  <input
                                    type="radio"
                                    value="0"
                                    checked={!isOutputJsonView}
                                    onChange={() => setIsOutputJsonView(false)}
                                  />
                                  {t("tree")}
                                </label>
                                <label className="btn outline border-radius-right-4">
                                  <input
                                    type="radio"
                                    value="1"
                                    checked={isOutputJsonView}
                                    onChange={() => setIsOutputJsonView(true)}
                                  />
                                  JSON
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    ) : null}
                  </div>
                </form>
              </Tab>
              {tableMode && (
                <Tab eventKey="simulation" title={t("simulation")}>
                  <form
                    className="mb-3"
                    onSubmit={handleRunSimulationTestConfirm}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                  >
                    <div
                      role="tabpanel"
                      className="tab-pane active mb-4"
                      aria-labelledby="simulation-tab"
                    >
                      <div className="pt-3 pb-2">
                        <strong>{t("synthetic_definition")}</strong>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <label>{t("sample_size")}</label>
                        </div>
                        <div className="col">
                          <select name="sample_size" className="form-control">
                            {sampleSizes.map((size) => (
                              <option value={size.value} key={size.value}>
                                {size.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-2">
                          <label>{t("store_sample")}</label>
                        </div>
                        <div className="col">
                          <div className="custom-control custom-switch w-100 z-index-100">
                            <input
                              type="checkbox"
                              defaultChecked={false}
                              className="custom-control-input w-100 z-index-100"
                              name="store_sample"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="store-simulation-sample"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="variables word-break mt-4">
                        {testingData.length > 0 &&
                          testingData.map((col) => {
                            return (
                              <div key={col.decision_table_column_id}>
                                {col.role === "condition" && (
                                  <div
                                    className="row mt-2"
                                    id={`sim-column-${
                                      tableMode
                                        ? col.decision_table_column_id
                                        : col
                                    }`}
                                  >
                                    <div className="col-2">
                                      <label>
                                        {tableMode ? col.title : col}
                                      </label>
                                    </div>
                                    <div className="col">
                                      <div className="btn-group">
                                        <div
                                          className={`input-group ${
                                            col.type === "list"
                                              ? "d-flex"
                                              : "d-none"
                                          }`}
                                        >
                                          <CreatableSelect
                                            name={`variable[${col.decision_table_column_id}][list][]`}
                                            defaultValue={testingSimulationValues.filter(
                                              (value) =>
                                                value.column_id ===
                                                col.decision_table_column_id
                                            )}
                                            isMulti
                                            hideSelectedOptions
                                            options={[]}
                                            formNoValidate
                                            components={{
                                              DropdownIndicator: () => null,
                                              IndicatorSeparator: () => null,
                                            }}
                                            IsValidNewOption={() => true}
                                            styles={customStyles}
                                          />
                                        </div>
                                        <div
                                          className={`input-group ${
                                            col.type === "interval"
                                              ? "d-flex"
                                              : "d-none"
                                          }`}
                                        >
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              (
                                            </span>
                                          </div>
                                          <input
                                            type="number"
                                            className="form-control"
                                            name={`variable[${col.decision_table_column_id}][value_a]`}
                                          />
                                          <div className="input-group-prepend input-group-append">
                                            <div className="input-group-text">
                                              ,
                                            </div>
                                          </div>
                                          <input
                                            type="number"
                                            className="form-control value_b sim-data"
                                            name={`variable[${col.decision_table_column_id}][value_b]`}
                                          />
                                          <div className="input-group-append">
                                            <span className="input-group-text">
                                              )
                                            </span>
                                          </div>
                                        </div>
                                        <select
                                          name={`variable[${col.decision_table_column_id}][value]`}
                                          className={`form-control sim-data ${
                                            col.type === "synthetic"
                                              ? "d-block"
                                              : "d-none"
                                          }`}
                                        >
                                          {syntheticOptions.map(
                                            (syntheticOption) => (
                                              <option
                                                value={syntheticOption.value}
                                                key={syntheticOption.value}
                                              >
                                                {syntheticOption.label}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <span className="sr-only">
                                            Toggle Dropdown
                                          </span>
                                        </button>
                                        <div className="dropdown-menu">
                                          <button
                                            className="dropdown-item sim-val-interval"
                                            type="button"
                                            onClick={() =>
                                              changeType(
                                                col.decision_table_column_id,
                                                "interval"
                                              )
                                            }
                                          >
                                            {t("interval")}
                                          </button>
                                          <button
                                            className="dropdown-item sim-val-interval"
                                            type="button"
                                            onClick={() =>
                                              changeType(
                                                col.decision_table_column_id,
                                                "synthetic"
                                              )
                                            }
                                          >
                                            {t("synthetic_data")}
                                          </button>
                                          <button
                                            className="dropdown-item sim-val-interval"
                                            type="button"
                                            onClick={() =>
                                              changeType(
                                                col.decision_table_column_id,
                                                "list"
                                              )
                                            }
                                          >
                                            {t("list")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col text-center">
                        <button
                          disabled={isLoading}
                          className="btn outline text-success d-flex align-items-center m-auto"
                          type="submit"
                        >
                          <WindIcon />{" "}
                          <span className="ml-2">{t("run_sim")}</span>
                        </button>
                      </div>
                    </div>
                    {loading && <Loader />}
                    {runTestSimulationResultData && (
                      <div className="mt-3">
                        <div className="mb-3">{t("simulation_results")}</div>
                        <div>
                          <pre className="test-result-data">
                            {testingData
                              .filter((test) => test.role === "result")
                              .map((test, itemIndex) => {
                                return (
                                  <div key={test.decision_table_column_id}>
                                    <h5>{test.title}</h5>
                                    {runTestSimulationResultData.length > 0 && (
                                      <table className="mb-2 table">
                                        <tbody>
                                          {runTestSimulationResultData[
                                            itemIndex
                                          ] &&
                                            Object.keys(
                                              runTestSimulationResultData[
                                                itemIndex
                                              ]
                                            ).map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <th>{item}</th>
                                                  <td>
                                                    {
                                                      Object.values(
                                                        runTestSimulationResultData
                                                      )[itemIndex][item]
                                                    }
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                );
                              })}
                          </pre>
                        </div>
                      </div>
                    )}
                  </form>
                </Tab>
              )}
              <Tab eventKey="object-test" title={t("object_test")}>
                <div
                  role="tabpanel"
                  className="tab-pane mb-4"
                  id="tab-object"
                  aria-labelledby="object-test-tab"
                >
                  <div className="row mt-2">
                    <div className="col-2">
                      <label>{t("data_object")}</label>
                    </div>
                    <div className="col">
                      <select
                        className="form-control"
                        onChange={(e) => setVectorId(e.target.value)}
                      >
                        {vectorsData?.length > 0 &&
                          vectorsData.map((vector) => {
                            if (toInteger(vector.vector_id) > 0)
                              return (
                                <option
                                  key={vector.vector_id}
                                  value={vector.vector_id}
                                >
                                  {vector.title}
                                </option>
                              );
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col text-center">
                      <button
                        className="btn outline text-success"
                        disabled={isLoading}
                        onClick={() =>
                          handleRunTestResultSuccessConfirm(vectorId)
                        }
                      >
                        <WindIcon /> {t("run_test")}
                      </button>
                    </div>
                  </div>

                  {loading && isModel && <Loader />}
                  {runTestResultStatusData && !loading ? (
                    isModel ? (
                      <>
                        <div className="row my-3">
                          <div className="col-4">{t("result_status")}</div>
                          <div
                            className={`col ${
                              runTestResultStatusData?.output?.test
                                ?.__status === "ok"
                                ? "alert-success"
                                : "alert-warning"
                            }`}
                          >
                            {runTestResultStatusData?.output?.test?.__status}
                          </div>
                        </div>
                        {runTestResultStatusData?.output?.test?.__status ===
                          "ok" && (
                          <div className="row mb-3">
                            <div className="col-4">{t("result_data")}</div>
                            <div className="col test-result-data">
                              {JSON.stringify(
                                runTestResultStatusData?.output?.test?.result
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="mt-3">
                        {isRuleSet &&
                          runTestResultStatusData?.output?.test?.rules_fired
                            ?.length > 0 && (
                            <>
                              <div className="mb-1">{t("rules_activated")}</div>
                              <div className="align-items-center d-flex flex-wrap mb-4">
                                {rulesFired?.map((item, index) => {
                                  return (
                                    <span
                                      key={item + index}
                                      className="badge badge-light badge-pill border font-size-14 mr-2 mt-2 px-2 py-1"
                                    >
                                      {item}
                                    </span>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        <div className="mb-3">{t("result_status")}</div>
                        {isFunction ? (
                          <div className="input-group-append pb-1">
                            <span className="input-group-text">
                              f(x) ={" "}
                              {Object.keys(functionData)?.length > 0
                                ? getFirstObject(
                                    runTestResultStatusData,
                                    functionData?.info?.x_attribute_path
                                  )
                                : ""}
                            </span>
                          </div>
                        ) : null}

                        <div
                          className="col-12 mb-md-0 mb-4"
                          style={{ height: "628px" }}
                        >
                          {runTestResultStatusData &&
                          Object.keys(runTestResultStatusData).length ? (
                            isInputJsonView ? (
                              <JsonStructure
                                jsonData={runTestResultStatusData}
                              />
                            ) : (
                              <ReleaseTreeTable
                                testingData={{
                                  current: runTestResultStatusData,
                                }}
                                type="output"
                              />
                            )
                          ) : null}
                          <div
                            className="convert-json btn-group-toggle"
                            data-toggle="buttons"
                          >
                            <label className="btn outline border-radius-left-4 active">
                              <input
                                type="radio"
                                value="0"
                                checked={!isInputJsonView}
                                onChange={() => setIsInputJsonView(false)}
                              />
                              {t("tree")}
                            </label>
                            <label className="btn outline border-radius-right-4">
                              <input
                                type="radio"
                                value="1"
                                checked={isInputJsonView}
                                onChange={() => setIsInputJsonView(true)}
                              />
                              JSON
                            </label>
                          </div>
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
              </Tab>
            </Tabs>
          ) : isDataSource ? (
            <>
              <form onSubmit={handleRunTestResultDetailsConfirm}>
                <div className="variables word-break">
                  {testingData?.length > 0 &&
                    testingData.map((attr, index) => {
                      return (
                        <div key={attr.name}>
                          <div className="row mt-2">
                            <div className="col-4">
                              <label>{attr.title || attr.name}</label>
                            </div>
                            <div className="col">
                              <input
                                type="hidden"
                                className="form-control"
                                name={`params[${index}][name]`}
                                defaultValue={attr.name}
                              />
                              <input
                                type="text"
                                defaultValue={attr.value}
                                className="form-control"
                                name={`params[${index}][value]`}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="tab-content mb-4">
                  <div className="row mt-3">
                    <div className="col text-center">
                      <button
                        className="btn outline"
                        disabled={isLoading}
                        type="submit"
                      >
                        <WindIcon /> {t("run_test")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              {loading && <Loader />}
              {runTestResultDetailsData &&
                Object.keys(runTestResultDetailsData).length && (
                  <div className="mt-3">
                    <div className="mb-3">
                      {tableMode ? t("result_status") : t("result_details")}
                    </div>
                    <div
                      className="col-12 mb-md-0 mb-4"
                      style={{ height: "628px" }}
                    >
                      {isOutputJsonViewDataSource ? (
                        <JsonStructure jsonData={runTestResultDetailsData} />
                      ) : (
                        <ReleaseTreeTable
                          testingData={{ current: runTestResultDetailsData }}
                          type="output"
                        />
                      )}
                      <div
                        className="convert-json btn-group-toggle"
                        data-toggle="buttons"
                      >
                        <label className="btn outline border-radius-left-4 active">
                          <input
                            type="radio"
                            value="0"
                            checked={!isOutputJsonViewDataSource}
                            onChange={() =>
                              setIsOutputJsonViewDataSource(false)
                            }
                          />
                          {t("tree")}
                        </label>
                        <label className="btn outline border-radius-right-4">
                          <input
                            type="radio"
                            value="1"
                            checked={isOutputJsonViewDataSource}
                            onChange={() => setIsOutputJsonViewDataSource(true)}
                          />
                          JSON
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              {/* Store Response Button and Form */}
              {runTestResultDetailsData && isDataSource && (
                <div className="mt-5 text-center">
                  {showStoreResponse && (
                    <div className="mt-3">
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("response_name")}
                          value={responseName}
                          onChange={(e) => setResponseName(e.target.value)}
                        />
                        <div className="input-group-append">
                          <Button
                            onClick={handleStoreSubmit}
                            className="btn outline text-success"
                            disabled={!responseName}
                          >
                            {t("store_save")}
                          </Button>
                          <Button
                            onClick={handleStoreCancel}
                            className="workflows-modal-btn outline ml-2"
                            variant="danger"
                          >
                            {t("cancel")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="tab-content mb-4">
              <div className="row mt-3">
                <div className="col text-center">
                  <button
                    className={`btn outline ${tableMode ? "text-success" : ""}`}
                    type="button"
                    onClick={handleRunTestResultDetailsConfirm}
                  >
                    <WindIcon /> {t("run_test")}
                  </button>
                </div>
              </div>
              {runTestResultDetailsData && (
                <div className="mt-3">
                  <div className="mb-3">
                    {tableMode ? t("result_status") : t("result_details")}
                  </div>
                  <div>
                    <pre>
                      {JSON.stringify(runTestResultDetailsData, null, 2)}
                    </pre>
                  </div>
                </div>
              )}
            </div>
          )}
          <Modal.Footer
            className={`${isDataSource ? "mt-4 justify-content-between" : ""}`}
          >
            {!showStoreResponse && isDataSource ? (
              <Button
                onClick={handleStoreResponse}
                className="btn outline workflows-modal-btn cursor-pointer"
              >
                {t("store_response")}
              </Button>
            ) : null}
            <Button
              variant=""
              onClick={() => handleClose()}
              type="button"
              className="m-0 outline workflows-modal-btn ml-auto"
            >
              {t("done")}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
};

TestingModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  testingData: PropTypes.any,
  setDecisionTableCols: PropTypes.func,
  vectorsData: PropTypes.array,
  title: PropTypes.string,
  size: PropTypes.string,
  handleRunTestResultDetailsConfirm: PropTypes.func,
  handleRunTestResultSuccessConfirm: PropTypes.func,
  handleRunSimulationTestConfirm: PropTypes.func,
  testingSimulationValues: PropTypes.array,
  runTestResultDetailsData: PropTypes.any,
  runTestResultStatusData: PropTypes.object,
  runTestSimulationResultData: PropTypes.any,
  tableMode: PropTypes.bool,
  isModel: PropTypes.bool,
  isDataSource: PropTypes.bool,
  isFunction: PropTypes.bool,
  isRuleSet: PropTypes.bool,
  isScorecard: PropTypes.bool,
  isDecisionTree: PropTypes.bool,
  loading: PropTypes.bool,
  rulesFired: PropTypes.array,
  rulesFiredDetails: PropTypes.array,
};

export default TestingModal;
