import React, { useEffect, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes, { node } from "prop-types";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as Close } from "assets/icons/close_modal.svg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { stepTitles } from "../../workflow/utils";
import { useTranslation } from "react-i18next";
import { MainContext } from "context/contexts";
import AutoSuggestInput from "../../autosuggest";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import NodeSplitCondition from "./NodeSplitCondition";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import NodeAction from "./NodeAction";
import { getVectors } from "utility/utility";

//decision tree context
import { DecisionTreeContext } from "context/DecisionTreeContext";
import { s, use } from "react-dom-factories";
import { cond } from "lodash";

const TreeNodeModal = (props) => {
  const { open, handleClose, handleDelete, data } = props;

  //decision tree context
  const { decisionTreeClone, setDecisionTreeClone, newNode, setIsFirstLoad } =
    useContext(DecisionTreeContext);

  const [decisionTreeData, setDecisionTreeData] = useState(decisionTreeClone);
  const [node, setNode] = useState(data);
  const [isRevision, setIsRevision] = useState(false);

  const { revisionId } = useParams();
  const { t } = useTranslation();

  //when the modal opens, set the node

  useEffect(() => {
    if (open && !revisionId) {
      const localDecisionTreeData = structuredClone(decisionTreeData);
      const specificNode = localDecisionTreeData?.node?.find(
        (node) => node.decision_tree_node_id === data.decision_tree_node_id
      );
      if (specificNode) {
        setNode(specificNode);
      }
    }
  }, [data]);

  //when the decisionTreeClone in context changes, update the decisionTreeData
  useEffect(() => {
    setDecisionTreeData(decisionTreeClone);
  }, [decisionTreeClone]);

  //main context vector data
  const { vectorsData, autoSuggestData, setIsLoading, setIsEdited } =
    useContext(MainContext);

  //set vetctordata clone
  const [vectorsDataClone, setVectorsDataClone] = useState([]);

  const new_condition = {
    value_a: "1",
    value_b: "",
    condition_type: "greater_than",
    leads_to_decision_tree_node_id: "",
    decision_tree_node_condition_id:
      "new_" + Math.random().toString(36).substring(7),
  };

  useEffect(() => {
    //find node in the structured clone
    const nodeIndex = decisionTreeData.nodes.findIndex(
      (node) => node.decision_tree_node_id === data.decision_tree_node_id
    );
    if (nodeIndex === -1) return; // Node not found, exit function
    setNode(decisionTreeData.nodes[nodeIndex]);

    //find parent and get its name
    const parentIndex = decisionTreeData.nodes.findIndex(
      (node) => node.decision_tree_node_id === data.parent_decision_tree_node_id
    );
    if (parentIndex === -1) return; // Node not found, exit function
    setNode((prev) => ({
      ...prev,
      parent_name: decisionTreeData.nodes[parentIndex].name,
    }));
  }, [decisionTreeData]);

  useEffect(() => {
    getVectors(autoSuggestData, setVectorsDataClone, true);
  }, [autoSuggestData]);

  const updateNode = (updatedNode) => {
    const nodeIndex = decisionTreeData.nodes.findIndex(
      (n) => n.decision_tree_node_id === updatedNode.decision_tree_node_id
    );

    if (nodeIndex !== -1) {
      const newTree = { ...decisionTreeData };
      newTree.nodes[nodeIndex] = updatedNode;
      setDecisionTreeData(newTree); // this will trigger a re-render
    }
  };

  function handleNodeDelete(nodeId) {
    // Clone the current state of the decision tree to avoid direct mutations
    const updatedDecisionTree = structuredClone(decisionTreeData);

    // Find the node by nodeId
    const nodeIndex = updatedDecisionTree.nodes.findIndex(
      (node) => node.decision_tree_node_id === nodeId
    );

    //parent node activity for deleting condition that leads to node
    const parentId =
      updatedDecisionTree.nodes[nodeIndex].parent_decision_tree_node_id;
    const parentNode = updatedDecisionTree.nodes.find(
      (node) => node.decision_tree_node_id === parentId
    );

    if (parentNode) {
      const conditionIndex = parentNode.conditions.findIndex(
        (condition) => condition.leads_to_decision_tree_node_id === nodeId
      );
      if (conditionIndex !== -1) {
        parentNode.conditions.splice(conditionIndex, 1);
      }
    }

    if (nodeIndex === -1) return; // Node not found, exit function

    // Get the node to delete
    getNodeIdsToDelete(nodeId).forEach((id) => {
      const nodeIndex = updatedDecisionTree.nodes.findIndex(
        (node) => node.decision_tree_node_id === id
      );
      updatedDecisionTree.nodes.splice(nodeIndex, 1);
    });
    // Update the decision tree context state
    setDecisionTreeData(updatedDecisionTree);
  }

  function getNodeIdsToDelete(nodeId) {
    const nodeIds = [nodeId];
    const children = decisionTreeData.nodes.filter(
      (node) => node.parent_decision_tree_node_id === nodeId
    );
    children.forEach((child) => {
      nodeIds.push(...getNodeIdsToDelete(child.decision_tree_node_id));
    });
    return nodeIds;
  }

  const handleConditionChange = (nodeId, conditionId, updatedConditionData) => {
    // Clone the current state of the decision tree to avoid direct mutations
    const updatedDecisionTree = structuredClone(decisionTreeData);

    // Find the node by nodeId
    const nodeIndex = updatedDecisionTree.nodes.findIndex(
      (node) => node.decision_tree_node_id === nodeId
    );

    if (nodeIndex === -1) return; // Node not found, exit function

    // Find the condition within the node by conditionId
    const conditionIndex = updatedDecisionTree.nodes[
      nodeIndex
    ].conditions.findIndex(
      (condition) => condition.decision_tree_node_condition_id === conditionId
    );
    if (conditionIndex === -1) return; // Condition not found, exit function
    // Update the condition with the new data
    updatedDecisionTree.nodes[nodeIndex].conditions[conditionIndex] = {
      ...updatedDecisionTree.nodes[nodeIndex].conditions[conditionIndex],
      ...updatedConditionData,
    };

    // Update the decision tree context state
    setDecisionTreeData(updatedDecisionTree);
  };

  const handleConditionDelete = (nodeId, conditionId) => {
    // Clone the current state of the decision tree to avoid direct mutations
    const updatedDecisionTree = structuredClone(decisionTreeData);

    // Find the node by nodeId
    const nodeIndex = updatedDecisionTree.nodes.findIndex(
      (node) => node.decision_tree_node_id === nodeId
    );

    if (nodeIndex === -1) return; // Node not found, exit function

    // Find the condition within the node by conditionId
    const conditionIndex = updatedDecisionTree.nodes[
      nodeIndex
    ].conditions.findIndex(
      (condition) => condition.decision_tree_node_condition_id === conditionId
    );
    if (conditionIndex === -1) return; // Condition not found, exit function

    //get the condition and the leads_to_decision_tree_node_id
    const condition =
      updatedDecisionTree.nodes[nodeIndex].conditions[conditionIndex];
    const leads_to_decision_tree_node_id =
      condition.leads_to_decision_tree_node_id;

    // Remove the condition from the node
    updatedDecisionTree.nodes[nodeIndex].conditions.splice(conditionIndex, 1);

    // Update the decision tree context state
    setDecisionTreeData(updatedDecisionTree);

    handleNodeDelete(leads_to_decision_tree_node_id);
  };

  //add new condition
  const handleAddCondition = (nodeId) => {
    //first let's check that attribute path is not empty
    if (!node.attribute_path) {
      return toast.error(t("attribute_path_is_required"), ToastOptions);
    }

    // Clone the current state of the decision tree to avoid direct mutations
    const updatedDecisionTree = structuredClone(decisionTreeData);

    // Find the node by nodeId
    const nodeIndex = updatedDecisionTree.nodes.findIndex(
      (node) => node.decision_tree_node_id === nodeId
    );

    if (nodeIndex === -1) return; // Exit if node not found

    // Find children of the node
    const children = updatedDecisionTree.nodes.filter(
      (node) => node.parent_decision_tree_node_id === nodeId
    );

    // Initialize conditions if not already done
    if (!updatedDecisionTree.nodes[nodeIndex].conditions) {
      updatedDecisionTree.nodes[nodeIndex].conditions = [];
    }

    const condition1_id = "new_" + Math.round(Math.random() * 1000);
    const condition2_id = "new_" + Math.round(Math.random() * 1000);

    const node1_id = "new_" + Math.round(Math.random() * 1000);
    const node2_id = "new_" + Math.round(Math.random() * 1000);

    // Determine actions based on the count of children
    if (children.length < 1 || !children) {
      // No existing children: add two conditions and two corresponding child nodes
      updatedDecisionTree.nodes[nodeIndex].conditions.push(
        {
          ...new_condition,
          decision_tree_node_condition_id: condition1_id,
          leads_to_decision_tree_node_id: node1_id,
        },
        {
          ...new_condition,
          decision_tree_node_condition_id: condition2_id,
          leads_to_decision_tree_node_id: node2_id,
          condition_type: "less_than_or_equal_to",
        }
      );

      for (let i = 0; i < 2; i++) {
        let newNodeId = structuredClone(newNode);
        let newNodeI = {
          ...newNodeId,
          name: "New Node " + i,
          decision_tree_node_id: i === 0 ? node1_id : node2_id,
          parent_decision_tree_node_id: nodeId,
          id: i === 0 ? node1_id : node2_id,
        };
        updatedDecisionTree.nodes.push(newNodeI);
      }
    } else {
      // Existing children: add one condition and one corresponding child node
      updatedDecisionTree.nodes[nodeIndex].conditions.push({
        ...new_condition,
        decision_tree_node_condition_id: condition1_id,
        leads_to_decision_tree_node_id: node1_id,
      });
      updatedDecisionTree.nodes.push({
        ...newNode,
        name: "New Node " + updatedDecisionTree.nodes.length,
        decision_tree_node_id: node1_id,
        parent_decision_tree_node_id: nodeId,
      });
    }

    // Update the decision tree state
    setDecisionTreeData(updatedDecisionTree);
  };

  // Handle the form submission
  const handleConfirm = (e) => {
    e.preventDefault();
    if (e.target.querySelector(".is-invalid")) {
      return toast.error(t("attribute_path_value_is_invalid"), ToastOptions);
    }

    const name = e.target.name.value;

    const updatedDecisionTree = structuredClone(decisionTreeData);

    const nodeIndex = updatedDecisionTree.nodes.findIndex(
      (node) => node.decision_tree_node_id == data.decision_tree_node_id
    );
    if (nodeIndex === -1) return; // Node not found, exit function
    //updatedDecisionTree.nodes[nodeIndex]
    //get the node, update the node values
    updatedDecisionTree.nodes[nodeIndex] = {
      ...updatedDecisionTree.nodes[nodeIndex],
      name,
    };

    setDecisionTreeClone(updatedDecisionTree);
    setIsFirstLoad(true);
    setIsEdited(true);
    //close the modal
    handleClose();
  };

  //actions

  const handleActionChange = (nodeId, actionId, updatedActionData) => {
    const updatedDecisionTree = structuredClone(decisionTreeData);

    const nodeIndex = updatedDecisionTree.nodes.findIndex(
      (node) => node.decision_tree_node_id === nodeId
    );
    if (nodeIndex === -1) return;

    //if the node does not have actions, create an empty array
    if (!updatedDecisionTree.nodes[nodeIndex].actions) {
      updatedDecisionTree.nodes[nodeIndex].actions = [];
    }

    const actionIndex = updatedDecisionTree.nodes[nodeIndex].actions.findIndex(
      (action) => action.decision_tree_node_action_id === actionId
    );
    if (actionIndex === -1) return;

    updatedDecisionTree.nodes[nodeIndex].actions[actionIndex] = {
      ...updatedDecisionTree.nodes[nodeIndex].actions[actionIndex],
      ...updatedActionData,
    };

    setDecisionTreeData(updatedDecisionTree);
  };

  const handleAddAction = (nodeId) => {
    setDecisionTreeData((prevTreeData) => {
      const updatedNodes = prevTreeData.nodes.map((node) => {
        if (node.decision_tree_node_id === nodeId) {
          const newAction = {
            action_value: "",
            attribute_path: "",
            decision_tree_node_action_id:
              "new_" + Math.round(Math.random() * 1000),
          };
          // If the node does not have any actions, create an empty array
          if (!node.actions) {
            node.actions = [];
          }

          return { ...node, actions: [...node.actions, newAction] };
        }
        return node;
      });
      return { ...prevTreeData, nodes: updatedNodes };
    });
  };

  const onUpdateAttributePath = (newAttributePath) => {
    const updatedNode = {
      ...node,
      attribute_path: newAttributePath,
    };
    setNode(updatedNode);
    updateNode(updatedNode);
  };

  const handleActionDelete = (nodeId, actionId) => {
    const updatedDecisionTree = structuredClone(decisionTreeData);
    const nodeIndex = updatedDecisionTree.nodes.findIndex(
      (node) => node.decision_tree_node_id === nodeId
    );
    if (nodeIndex === -1) return;

    const actions = updatedDecisionTree.nodes[nodeIndex].actions;
    const actionIndex = actions.findIndex(
      (action) => action.decision_tree_node_action_id === actionId
    );
    if (actionIndex === -1) return;

    // Remove the action from the array
    actions.splice(actionIndex, 1);

    // Update the node's actions in the decision tree
    updatedDecisionTree.nodes[nodeIndex].actions = actions;

    // Update the state with the modified decision tree
    setDecisionTreeData(updatedDecisionTree);
  };

  return (
    <>
      <Modal dialogClassName="w-1300" show={open} onHide={handleClose} centered>
        <Modal.Header>
          <h5 className="modal-workflow__title d-flex align-items-center modal-workflow__title_color">
            {node.name}
          </h5>
          <button type="button" className="close" onClick={handleClose}>
            <Close />
          </button>
        </Modal.Header>
        <form onSubmit={handleConfirm}>
          <Modal.Body className={revisionId ? "mb-3" : ""}>
            <input type="hidden" name="decision_tree_node_id" value={node.id} />
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="node-title">{t("title_capitalized")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="node-title"
                  name="name"
                  defaultValue={node.name || ""}
                  disabled={!!revisionId}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="parent-node">{t("parent_node")}</label>
                <div className="d-flex align-items-center">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    id="parent-node"
                    placeholder={
                      node.parent_decision_tree_node_id
                        ? node.parent_name
                        : "Start"
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
            <label>{t("conditions")}</label>
            <div className="form-row bg-gray p-3">
              <div className="form-group col-md-6 ">
                <div className="col mr-0 ml-0 subflow-option">
                  <div className={`input-group`}>
                    <div className="bg-white border border-radius-left-4 input-group-prepend w-150">
                      <div
                        className="align-items-center d-flex font-size-14 pl-2 text-gray-66"
                        htmlFor={"attribute_path"}
                      >
                        {t("attribute_path")}
                      </div>
                    </div>
                    <AutoSuggestInput
                      revisionMode={!!revisionId}
                      vectorsData={vectorsDataClone}
                      id={"attribute_path"}
                      defaultValue={node.attribute_path || ""}
                      onInputChange={onUpdateAttributePath}
                      inputName={"attribute_path"}
                    />
                  </div>
                </div>
                {/* <div className="col mr-0 ml-0 subflow-option mt-2">
                  <div className={`input-group`}>
                    <div className="bg-white border border-radius-left-4 input-group-prepend w-150">
                      <span className="align-items-center d-flex font-size-14 pl-2 text-gray-66">
                        {t("alias")}
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name="attribute_name"
                      style={{ borderLeft: "0 !important" }}
                      defaultValue={node.attribute_name || ""}
                      onChange={(e) => {
                        const updatedNode = {
                          ...node,
                          attribute_name: e.target.value,
                        };
                        updateNode(updatedNode);
                      }}
                    />
                  </div>
                </div>
                */}
              </div>
              <div className="form-group col-md-1"></div>
              <div className="form-group col-md-5">
                <label htmlFor="value">Splitting</label>
                <div className="align-items-center">
                  {node.conditions &&
                    node.conditions.map((condition, index) => (
                      <div key={index}>
                        <NodeSplitCondition
                          key={index}
                          nodeId={node.decision_tree_node_id}
                          condition={condition}
                          handleConditionChange={handleConditionChange}
                          handleConditionDelete={handleConditionDelete}
                          isReadOnly={!!revisionId}
                        />
                      </div>
                    ))}
                  {!revisionId && (
                    <button
                      type="button"
                      className="btn outline"
                      onClick={() =>
                        handleAddCondition(node.decision_tree_node_id)
                      }
                    >
                      <PlusIcon
                        style={{
                          filter: "brightness(0.5)",
                        }}
                      />
                      <span className="ml-2">{t("split")}</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4">
              <label className="font-size-18">{t("actions")}</label>
              <div className="form-group bg-gray p-3">
                {node.actions &&
                  node.actions.map((action, index) => (
                    <NodeAction
                      key={index}
                      action={action}
                      nodeId={node.decision_tree_node_id}
                      handleActionChange={handleActionChange}
                      handleActionDelete={handleActionDelete}
                      vectorsData={vectorsDataClone}
                      isReadOnly={!!revisionId}
                    />
                  ))}
                {!revisionId && (
                  <div className="form-row justify-content-center">
                    <button
                      type="button"
                      className="btn outline"
                      onClick={() =>
                        handleAddAction(node.decision_tree_node_id)
                      }
                    >
                      <PlusIcon style={{ filter: "brightness(0.5)" }} />
                      <span className="ml-2">{t("add_action")}</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          {!revisionId ? (
            <Modal.Footer className="justify-content-between">
              {(data.parent_decision_tree_node_id && (
                <div
                  title={t("delete_this_step")}
                  className="cursor-pointer text-danger"
                  onClick={handleDelete}
                >
                  {t("delete")}
                </div>
              )) || <div></div>}
              <div className={`d-flex `}>
                <button type="submit" className="workflows-modal-btn mr-1">
                  {t("close")}
                </button>
              </div>
            </Modal.Footer>
          ) : null}
        </form>
      </Modal>
    </>
  );
};

TreeNodeModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  title: PropTypes.string,
  handleConfirm: PropTypes.func,
  data: PropTypes.object,
  decisionTree: PropTypes.object,
};

export default TreeNodeModal;
