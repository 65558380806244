import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  createVectorAttributeRequest,
  deleteVectorAttributeRequest,
  deleteVectorRequest,
  getVectorAttributesRequest,
  getVectorRequest,
  getVectorsRequest,
  updateVectorAttributeRequest,
  updateVectorRequest,
} from "redux/vectors/action";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import usePrevious from "utility/hooks/usePrevious";
import CreateDataObjectAttribute from "components/modals/dataObjects/CreateDataObjectAttribute";
import DeleteConfirm from "components/modals/DeleteConfirm";
import CreateDataObject from "components/modals/dataObjects/CreateDataObject";
import VisualizeJson from "components/modals/dataObjects/VisualizeJSON";
import SubHeader from "components/SubHeader";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as PlusIcon } from "assets/icons/circle-plus.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit-small.svg";
import { ReactComponent as ExportIcon } from "assets/icons/export.svg";
import { ReactComponent as JsonVisualize } from "assets/icons/json.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as VectorsIcon } from "assets/icons/vectors.svg";
import { MainContext } from "context/contexts";
import { isJson, sortByMapping, TableNoItems } from "utility/utility";
import moment from "moment";
import { CreateCopyOfDataObject } from "./utils";
import EditTitle from "../modals/dataObjects/EditTitle";
import { useTranslation } from "react-i18next";
import { AttributeRow } from "./AttributeRow";

const DataObject = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    vector,
    isGetVectorSuccess,
    isGetVectorError,
    isGetVectorAttributesSuccess,
    isGetVectorAttributesError,
    isCreatedVectorAttributeSuccess,
    isCreatedVectorAttributeError,
    isDeletedVectorSuccess,
    isDeletedVectorError,
    isUpdatedVectorRequest,
    isUpdatedVectorSuccess,
    isUpdatedVectorError,
    isUpdatedVectorAttributeSuccess,
    isUpdatedVectorAttributeError,
    isDeletedVectorAttributeSuccess,
    isDeletedVectorAttributeError,
    vectorAttributes,
  } = useSelector((state) => state.vectors);

  const prevIsGetVectorSuccess = usePrevious(isGetVectorSuccess);
  const prevIsGetVectorError = usePrevious(isGetVectorError);
  const prevIsGetVectorAttributesSuccess = usePrevious(
    isGetVectorAttributesSuccess
  );
  const prevIsGetVectorAttributesError = usePrevious(
    isGetVectorAttributesError
  );
  const prevIsUpdatedVectorSuccess = usePrevious(isUpdatedVectorSuccess);
  const prevIsDeletedVectorSuccess = usePrevious(isDeletedVectorSuccess);
  const prevIsDeletedVectorError = usePrevious(isDeletedVectorError);
  const prevIsUpdatedVectorError = usePrevious(isUpdatedVectorError);
  const prevIsUpdatedVectorAttributeError = usePrevious(
    isUpdatedVectorAttributeError
  );
  const prevIsDeletedVectorAttributeError = usePrevious(
    isDeletedVectorAttributeError
  );
  const prevIsUpdatedVectorAttributeSuccess = usePrevious(
    isUpdatedVectorAttributeSuccess
  );
  const prevIsDeletedVectorAttributeSuccess = usePrevious(
    isDeletedVectorAttributeSuccess
  );
  const prevIsCreatedVectorAttributeSuccess = usePrevious(
    isCreatedVectorAttributeSuccess
  );
  const prevIsCreatedVectorAttributeError = usePrevious(
    isCreatedVectorAttributeError
  );

  const [vectorData, setVectorData] = useState({});
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [isAddAttrModalOpen, setIsAddAttrModalOpen] = useState(false);
  const [isEditAttrModalOpen, setIsEditAttrModalOpen] = useState(false);
  const [isDeleteVectorModalOpen, setIsDeleteVectorModalOpen] = useState(false);
  const [isAddVectorModalOpen, setIsAddVectorModalOpen] = useState(false);
  const [isVisualizeModalOpen, setIsVisualizeModalOpen] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState([]);

  const [
    isDeleteVectorAttributeModalOpen,
    setIsDeleteVectorAttributeModalOpen,
  ] = useState(false);
  const [isCreateNewFromListOpen, setIsCreateNewFromListOpen] = useState(false);
  const [isEditTitleOpen, setIsEditTitleOpen] = useState(false);

  useEffect(() => {
    document.title = `${t("data_object")} - Decisimo`;
    setIsLoading(true);
    dispatch(getVectorRequest(id));
    dispatch(getVectorAttributesRequest(id));
  }, [id]);

  useEffect(() => {
    if (isGetVectorSuccess && prevIsGetVectorSuccess === false) {
      setIsLoading(false);
      const vectorClone = structuredClone(vector);
      vectorClone?.attributes?.sort(sortByMapping);
      setVectorData(vectorClone);
    }
  }, [isGetVectorSuccess]);

  useEffect(() => {
    if (
      (isGetVectorError && prevIsGetVectorError === false) ||
      (isDeletedVectorError && prevIsDeletedVectorError === false) ||
      (isUpdatedVectorError && prevIsUpdatedVectorError === false) ||
      (isUpdatedVectorAttributeError &&
        prevIsUpdatedVectorAttributeError === false) ||
      (isDeletedVectorAttributeError &&
        prevIsDeletedVectorAttributeError === false) ||
      (isCreatedVectorAttributeError &&
        prevIsCreatedVectorAttributeError === false) ||
      (isGetVectorAttributesError &&
        prevIsGetVectorAttributesError === false) ||
      (isGetVectorAttributesSuccess &&
        prevIsGetVectorAttributesSuccess === false)
    ) {
      if (isUpdatedVectorError) {
        handleAddDataObjectClose();
      }
      setIsLoading(false);
    }
  }, [
    isGetVectorAttributesSuccess,
    isGetVectorError,
    isGetVectorAttributesError,
    isDeletedVectorError,
    isUpdatedVectorError,
    isUpdatedVectorAttributeError,
    isDeletedVectorAttributeError,
    isCreatedVectorAttributeError,
  ]);

  useEffect(() => {
    if (isDeletedVectorSuccess && prevIsDeletedVectorSuccess === false) {
      toast.warning(t("data_object_deleted"), ToastOptions);
      dispatch(getVectorsRequest());
      navigate("/data-objects");
    }
  }, [isDeletedVectorSuccess]);

  useEffect(() => {
    if (isUpdatedVectorSuccess && prevIsUpdatedVectorSuccess === false) {
      setIsLoading(false);
      const toastText = isVisualizeModalOpen
        ? t("data_object_updated")
        : !isEditTitleOpen
        ? t("title_updated")
        : t("data_object_re_uploaded");
      toast.success(toastText, ToastOptions);
      setIsVisualizeModalOpen(false);
      handleAddDataObjectClose();
      dispatch(getVectorRequest(id));
      dispatch(getVectorAttributesRequest(id));
      dispatch(getVectorsRequest());
    }
  }, [isUpdatedVectorSuccess]);

  useEffect(() => {
    if (
      isUpdatedVectorAttributeSuccess &&
      prevIsUpdatedVectorAttributeSuccess === false
    ) {
      setIsLoading(false);
      setDisabledInputs([]);
      setSelectedAttribute({});
      toast.success(t("attribute_saved"), ToastOptions);
      dispatch(getVectorAttributesRequest(id));
    }
  }, [isUpdatedVectorAttributeSuccess]);

  useEffect(() => {
    if (
      isDeletedVectorAttributeSuccess &&
      prevIsDeletedVectorAttributeSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(t("data_object_attribute_deleted"), ToastOptions);
      dispatch(getVectorRequest(id));
      dispatch(getVectorAttributesRequest(id));
      dispatch(getVectorsRequest());
    }
  }, [isDeletedVectorAttributeSuccess]);

  useEffect(() => {
    if (
      isCreatedVectorAttributeSuccess &&
      prevIsCreatedVectorAttributeSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("attribute_added"), ToastOptions);
      dispatch(getVectorRequest(id));
      dispatch(getVectorAttributesRequest(id));
      dispatch(getVectorsRequest());
    }
  }, [isCreatedVectorAttributeSuccess]);

  const handleDeleteConfirm = () => {
    setIsLoading(true);
    dispatch(deleteVectorRequest(id));
    setIsDeleteVectorModalOpen(false);
  };

  const handleShowVectorAttrDeleteModal = (attr) => {
    setSelectedAttribute(attr);
    setIsDeleteVectorAttributeModalOpen(true);
  };

  const handleDeleteAttrConfirm = () => {
    const vector = structuredClone(vectorData);
    vector.attributes = vector.attributes.filter(
      (attr) =>
        attr.vector_attribute_id !== selectedAttribute.vector_attribute_id
    );
    setVectorData(vector);
    const attrId = selectedAttribute.vector_attribute_id;
    setIsLoading(true);
    dispatch(deleteVectorAttributeRequest({ id, attrId }));
    setIsDeleteVectorAttributeModalOpen(false);
  };

  const handleShowAttributeEditModal = (attribute, id) => {
    setSelectedAttribute(attribute);
    setDisabledInputs((prevDisabledInputs) =>
      prevDisabledInputs.includes(id)
        ? prevDisabledInputs.filter((disabledId) => disabledId !== id)
        : [...prevDisabledInputs, id]
    );
  };

  const handleConfirmAddAttr = (target) => {
    if (!target.querySelector(".is-invalid")) {
      target.mapping.value = target.mapping.value.replace(/\s/g, "");
      const data = new URLSearchParams(new FormData(target));

      setIsLoading(true);
      dispatch(createVectorAttributeRequest({ id, data }));
      setIsAddAttrModalOpen(false);
    } else {
      toast.error(t("invalid_mapping_path"), ToastOptions);
    }
  };

  const handleConfirmEditAttr = (e, attrId, index) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const mapping = formData.get(`mapping-${index}`);
    const dummy_value = formData.get(`dummy_value-${index}`);

    if (mapping.startsWith("$.") && mapping.length >= 3) {
      const vector = structuredClone(vectorData);
      const attr = vector.attributes.find(
        (attr) => attr.vector_attribute_id === attrId
      );
      attr.mapping = mapping;
      attr.dummy_value = dummy_value;
      setVectorData(vector);

      const data = new URLSearchParams();
      data.set("mapping", mapping);
      data.set("dummy_value", dummy_value);

      setIsLoading(true);
      dispatch(updateVectorAttributeRequest({ id, attrId, data }));
    } else {
      toast.error(t("invalid_mapping_path"), ToastOptions);
    }
  };

  const handleAddDataObjectClose = () => {
    setIsAddVectorModalOpen(false);
  };

  const handleAddDataObjectConfirm = (e, selectedFile) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new URLSearchParams(new FormData(e.target));
    if (selectedFile) {
      if (selectedFile.type.includes("json")) {
        const fileReader = new FileReader();
        fileReader.onload = (evt) => {
          if (isJson(evt.target.result)) {
            data.set(
              "json_vector",
              JSON.stringify(JSON.parse(evt.target.result))
            );
            dispatch(updateVectorRequest({ id, data }));
          } else {
            setIsLoading(false);
            return toast.error(t("select_valid_json"), ToastOptions);
          }
        };
        fileReader.readAsText(selectedFile);
      } else {
        setIsLoading(false);
        return toast.error(t("select_valid_json"), ToastOptions);
      }
    } else {
      data.set("json_vector", null);
      dispatch(updateVectorRequest({ id, data }));
    }
  };

  const exportData = () => {
    if (vectorData?.title) {
      const vectorAttributesClone = structuredClone(vectorAttributes);
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(vectorAttributesClone)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      const title = structuredClone(vectorData?.title)
        .toLowerCase()
        .replace(/ /g, "_");

      link.download = `${title}_${moment(new Date()).format()}.json`;

      link.click();
      toast.success(t("downloaded_successfully"), ToastOptions);
    } else {
      return toast.error(t("something_went_wrong"), ToastOptions);
    }
  };

  const handleConfirmVisualizeDataObject = (json = {}) => {
    if (isJson(json)) {
      const data = new URLSearchParams(new FormData());
      data.set("json_vector", json);
      dispatch(updateVectorRequest({ id, data }));
    } else {
      toast.error(t("select_valid_json"), ToastOptions);
    }
  };

  const handleConfirmUpdateTitle = (e) => {
    e.preventDefault();
    setIsEditTitleOpen(false);
    const data = new URLSearchParams(new FormData(e.target));
    dispatch(updateVectorRequest({ id, data }));
  };

  const changeValue = (e) => {
    const val = e.target.value;
    if (!(val.startsWith("$.") && val.length >= 3)) {
      e.target.classList.add("is-invalid");
    } else {
      e.target.classList.remove("is-invalid");
    }
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          onClick={() => setIsEditTitleOpen(true)}
          type="button"
        >
          <EditIcon /> {t("edit_title")}
        </button>
      ),
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          onClick={() => setIsCreateNewFromListOpen(true)}
          type="button"
        >
          <CopyIcon /> {t("make_a_copy")}
        </button>
      ),
    },
    {
      id: 3,
      content: (
        <button
          className="dropdown-item"
          onClick={() => setIsAddVectorModalOpen(true)}
          type="button"
        >
          <UploadIcon /> {t("re_upload")}
        </button>
      ),
    },
    {
      id: 4,
      content: (
        <button className="dropdown-item" onClick={exportData} type="button">
          <ExportIcon /> {t("download")}
        </button>
      ),
      divider: true,
    },
    {
      id: 5,
      content: (
        <button
          className="dropdown-item"
          onClick={() => setIsDeleteVectorModalOpen(true)}
          type="button"
        >
          <TrashIcon /> {t("delete")}
        </button>
      ),
    },
  ];

  return (
    <>
      <SubHeader
        alt={t("data_object")}
        title={vectorData?.title}
        icon={<VectorsIcon />}
        actions={
          <>
            <button
              className="btn outline text-nowrap mr-2"
              onClick={() => setIsAddAttrModalOpen(true)}
            >
              <PlusIcon /> <span className="ml-2">{t("add_attribute")}</span>
            </button>
            <button
              className="btn outline mr-2 py-1"
              onClick={() => setIsVisualizeModalOpen(true)}
              title={t("data_object")}
            >
              <JsonVisualize />
            </button>
            <div className="btn-group mr-1">
              <button
                title={t("data_object_settings")}
                type="button"
                className="btn primary d-inline-block border-radius-left-4"
              >
                <SettingsIcon />
              </button>
              <div className="btn-group">
                <button
                  title={t("data_object_info")}
                  className="btn dropdown-toggle dropdown-toggle-split primary border-radius-right-4"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="sr-only">{t("toggle_dropdown")}</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                  {settings.length > 0 &&
                    settings.map((setting) => {
                      return (
                        <span key={setting.id}>
                          {setting.content}
                          {setting.divider && (
                            <div className="dropdown-divider" />
                          )}
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        }
      />
      {isGetVectorSuccess && vector?.attributes[0] !== null && (
        <>
          <div
            className="mb-5"
            style={{ height: "calc(100vh - 200px)" }}
            id="data-object-details"
          >
            <AutoSizer>
              {({ height, width }) => (
                <>
                  <div
                    style={{
                      width,
                      height: 40,
                      borderBottom: "1px solid #e5e5e5",
                    }}
                    className="d-flex"
                  >
                    <div style={{ width: "47%" }}>{t("attribute_mapping")}</div>
                    <div style={{ width: "47%" }}>{t("dummy_value")}</div>
                    <div style={{ width: "6%" }}>{t("actions")}</div>
                  </div>
                  {vectorData?.attributes?.length > 0 &&
                  vectorData?.attributes[0] !== null ? (
                    <List
                      height={height - 40}
                      itemCount={vectorData.attributes.length}
                      itemSize={50}
                      width={width}
                    >
                      {({ index, style }) => (
                        <AttributeRow
                          index={index}
                          style={style}
                          attribute={vectorData.attributes[index]}
                          disabledInputs={disabledInputs}
                          changeValue={changeValue}
                          handleConfirmEditAttr={handleConfirmEditAttr}
                          handleShowAttributeEditModal={
                            handleShowAttributeEditModal
                          }
                          handleShowVectorAttrDeleteModal={
                            handleShowVectorAttrDeleteModal
                          }
                        />
                      )}
                    </List>
                  ) : null}
                </>
              )}
            </AutoSizer>
          </div>
        </>
      )}
      {isGetVectorSuccess && vector?.attributes[0] === null && (
        <>
          <div className="d-flex justify-content-center align-items-center h-100">
            {t("no_items_here")}
          </div>
        </>
      )}
      {isAddAttrModalOpen ? (
        <CreateDataObjectAttribute
          open={isAddAttrModalOpen}
          handleClose={() => setIsAddAttrModalOpen(false)}
          handleConfirm={handleConfirmAddAttr}
          data={{}}
        />
      ) : null}
      {isEditAttrModalOpen ? (
        <CreateDataObjectAttribute
          open={isEditAttrModalOpen}
          handleClose={() => setIsEditAttrModalOpen(false)}
          handleConfirm={handleConfirmEditAttr}
          data={selectedAttribute}
          editMode={true}
        />
      ) : null}
      <DeleteConfirm
        open={isDeleteVectorModalOpen}
        handleClose={() => setIsDeleteVectorModalOpen(false)}
        title={t("delete_data_object")}
        message={t("do_you_want_to_delete_this_data_object")}
        handleConfirm={handleDeleteConfirm}
      />
      <CreateDataObject
        isLoading={isUpdatedVectorRequest}
        handleClose={handleAddDataObjectClose}
        handleConfirm={handleAddDataObjectConfirm}
        open={isAddVectorModalOpen}
        title={t("re_upload_data_object")}
        editMode={true}
      />
      <DeleteConfirm
        open={isDeleteVectorAttributeModalOpen}
        handleClose={() => setIsDeleteVectorAttributeModalOpen(false)}
        title={t("delete_data_object_attribute")}
        message={t("do_you_want_to_delete_this_data_attribute")}
        handleConfirm={handleDeleteAttrConfirm}
      />
      <VisualizeJson
        open={isVisualizeModalOpen}
        handleClose={() => setIsVisualizeModalOpen(false)}
        handleConfirm={handleConfirmVisualizeDataObject}
        data={vectorAttributes ? JSON.stringify(vectorAttributes, null, 2) : ""}
      />
      <CreateCopyOfDataObject
        isCreateNewFromListOpen={isCreateNewFromListOpen}
        setIsCreateNewFromListOpen={setIsCreateNewFromListOpen}
        editMode={true}
      />
      <EditTitle
        open={isEditTitleOpen}
        handleClose={() => setIsEditTitleOpen(false)}
        handleConfirm={handleConfirmUpdateTitle}
      />
    </>
  );
};

export default DataObject;
